<div *ngIf="!mobile">
    <div *ngIf="selected_unit_id.length>0 && unit_access.POST">
        <div *ngIf="floor" class="switch1">
            <div *ngIf="restrictDummy(selected_floor.type) == false" class="icon-container1" matTooltip="Move to Floor">
                <mat-icon id="floor-icon-disabled">call_made</mat-icon>
            </div>
            <div *ngIf="restrictDummy(selected_floor.type) == true" (click)="openDialog('moveUnit')" class="icon-container1" matTooltip="Move to Floor">
                <mat-icon id="floor-icon">call_made</mat-icon>
            </div>
            <div (click)="openDialog('floorPasteUnit')" class="icon-container1" matTooltip="Paste in Floor">
                <mat-icon id="floor-icon" svgIcon="floor"></mat-icon>
            </div>
            <div (click)="openDialog('floorPasteUnit',{same_floor:true})" class="icon-container1" matTooltip="Paste here">
                <mat-icon id="floor-icon" svgIcon="unit"></mat-icon>
            </div>
            <div></div>
        </div>
        <div class="floating1 animated bounceIn delay-2s">
            <div (click)="showFloorNUnit()" class="round">
                <div>
                    <mat-icon id="shuffle" svgIcon="shuffle"></mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="border">
        <div id="add-btn">
            <button *ngIf="unit_access.POST" [disabled]="isMap" (click)="openDialog('addUnit')" class="primary-btn ovrd" >
                Add Unit
                <mat-icon class="s24p">add</mat-icon>
            </button>
            <div mat-button (click)="redirectMap()" *ngIf="isMap" class="web_circle" title="map">
                <mat-icon>map</mat-icon>
            </div>
            <!-- <ng-container *ngIf="selected_unit_id != null || selected_unit_id != undefined">
                <button *ngIf="unit_access.DELETE && selected_unit_id.length == 1" mat-button
                    (click)="openDialog('remove')" class="redbutton">
                    <mat-icon id="delete" svgIcon="delete"></mat-icon>
                </button>
            </ng-container> -->

            <div mat-button class="box1" title="select all">
                <ng-container *ngIf="unitData">
                    <mat-checkbox *ngIf="changeFilter == 0" (change)="selectAllUnit($event)" [checked]="selected_unit_id.length == unitData.length && unitData.length != 0"></mat-checkbox>
                </ng-container>
                <ng-container *ngIf="unitDataGis">
                    <mat-checkbox *ngIf="changeFilter == 1" (change)="selectAllGisUnit($event)" [checked]="select_import.length == unitDataGis.length && unitDataGis.length != 0">
                    </mat-checkbox>
                </ng-container>
            </div>
            <button *ngIf="unit_access.POST && select_import.length" mat-button (click)="openDialog('addUnit',{'gis':true,'bulk':true})" class="redbutton">
                <mat-icon id="import">add_box</mat-icon>
            </button>
        </div>
        <div class="search">
            <div class="searchBtn">
                <div class="box">
                    <div>
                        <input type="search" class="search-input" [formControl]="searchField" placeholder="Enter Unit UID">
                    </div>
                    <div id="mag" title="search">
                        <mat-icon class="magnify">search</mat-icon>
                    </div>
                </div>
            </div>
            <ng-container>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon class="applied" svgIcon="filter">
                        <div class="fixed-badge">
                            <i *ngIf="filterObj && filterObj.class"
                                class="material-icons {{filterObj.class}}">fiber_manual_record</i>
                            <img *ngIf="filterObj && filterObj.icon" class="all-select mr-1" [src]="filterObj.icon"
                                alt="All">
                        </div>
                    </mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <div class="z1">
                        <button mat-menu-item *ngFor="let edit of Edited" class="align-c" (click)="onedited(edit)">
                            <i *ngIf="edit && edit.class" class="material-icons {{edit.class}}">fiber_manual_record</i>
                            <span>{{edit.name}}</span>
                        </button>
                    </div>
                    <div class="z2">
                        <button mat-menu-item *ngFor="let st of statusFilters" class="align-c" (click)="filterUnitList(st)">
                            <i *ngIf="st && st.class" class="material-icons {{st.class}}">fiber_manual_record</i>
                            <img *ngIf="st && st.icon" class="all-select mr-1" [src]="st.icon" alt="All">
                            <span>{{st.name}}</span>
                        </button>
                    </div>
                </mat-menu>
            </ng-container>
        </div>
        <!-- <mat-tab-group> -->
            <!-- <mat-tab label="Estater Units [{{estaterUnitCount}}]"> -->
                <div class="list-container">
                    <div class="block1" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                        [scrollWindow]="false" (scrolled)="onScroll()">
                        <div cdkDropList (cdkDropListDropped)="sortWebFloor($event)">
                            <div *ngFor="let data of unitData" cdkDrag>
                                <div class="cdkDrop-area" *cdkDragPlaceholder></div>
                                <div class="big-button-m" [class.red]="data.is_deleted == true" [class.gradient]="data.unit_type_name == 'not_retail' || data.unit_type_name == 'path'" id="unit_{{data.id}}"
                                    (click)="openOptions(data,false)"
                                    [ngClass]="{'actUnit': isOn(data)}">
                                    <div id="image" [ngStyle]="{'background': 'linear-gradient(to top, black , transparent), url('+data.image+')', 'background-repeat':'no-repeat', 'background-size': 'cover'}">
                                        <mat-checkbox (change)="onCheckStatus($event)" value="{{data.id}}" class="checkB" [checked]="ifChecked(data.id)"></mat-checkbox>
                                        <span id="unit-id">{{data.uid}}</span>
                                        <!-- <img id="i-web" src="{{data.image}}" alt="Apartment"> -->
                                    </div>
                                    <div id="data">
                                        <div id="title" [ngClass]="{ 'rework_unit': data.status == 4, 'rework_unit_status': data.status == 6}">
                                            <div class="title1" *ngIf="data.uid">
                                                <p>{{data.unit_number}}</p>
                                                <span *ngIf="data.unit_number.length > 9" class="tooltiptext">{{data.unit_number}}</span>
                                            </div>
                                            <div>{{getpropertypeLable(data.property_type_name)}}</div>
                                            <div (click)="changeVacant(data)" id="vacant_{{data.id}}" matTooltip="{{isVacantMessage(data.is_vacant)}}">
                                                <mat-icon [ngClass]="{'filled': data.is_vacant}" id="filled" svgIcon="vacant">
                                                </mat-icon>
                                            </div>
                                        </div>
                                        <div id="bottom">
                                            <div id="one">
                                                <div>Unit Type</div>
                                                <div>
                                                    <strong>{{data.unit_type}}</strong>
                                                </div>
                                            </div>
                                            <div id="one">
                                                <div>Sale Price</div>
                                                <div>
                                                    <strong>{{data.currency}}<span [ngClass]="{'visibility-hidden': data.sale_price === 0}">{{data.sale_price}} </span></strong>
                                                </div>
                                            </div>
                                            <div id="one">
                                                <div>Lease Price</div>
                                                <div>
                                                    <strong>{{data.currency}} <span [ngClass]="{'visibility-hidden': data.lease_price === 0}">{{data.lease_price}}</span></strong>
                                                </div>
                                            </div>
                                            <div id="one">
                                                <div>Area</div>
                                                <div class="hfx" title="{{data.unit_area}}">
                                                    <strong><span [ngClass]="{'visibility-hidden': data.unit_area === 0}">{{data.unit_area}}</span></strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="(loader || loading) || (loader && loading) || isAttributeLoaded" class='loader-div'>
                            <ngx-loading [show]="(loader || loading) || (loader && loading) || isAttributeLoaded" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                            </ngx-loading>
                        </div>
                    </div>
                </div>
            <!-- </mat-tab>
        </mat-tab-group> -->
    </div>
</div>



<div *ngIf="mobile">
    <app-mobile-toolbar [back]="true" [title]="'Units'" [totalCount]="estaterUnitCount" [property_uid]="selected_property.property_id" [customClickAction]="true" [customObj]="this" [floor_name]="selected_floor.name"></app-mobile-toolbar>

    <div id="add-btn-m">
        <div class="left-btn" *ngIf="unit_access.PATCH">
            <button (click)="sortOrder('up')" mat-button [disabled]="selected_unit_id.length != 1" class="arrow-btn">
                <mat-icon id="arrow" class="icon-up" svgIcon="up"></mat-icon>
            </button>
            <button (click)="sortOrder('down')" mat-button [disabled]="selected_unit_id.length != 1" class="arrow-btn ml-1">
                <mat-icon id="arrow" class="icon-down" svgIcon="down"></mat-icon>
            </button>
            <div mat-button class="box1">
                <ng-container *ngIf="unitData">
                    <mat-checkbox *ngIf="changeFilter == 0" (change)="selectAllUnit($event)" [checked]="selected_unit_id.length == unitData.length && unitData.length != 0"></mat-checkbox>
                </ng-container>
                <ng-container *ngIf="unitDataGis">
                    <mat-checkbox *ngIf="changeFilter == 1" (change)="selectAllGisUnit($event)" [checked]="select_import.length == unitDataGis.length && unitDataGis.length != 0">
                    </mat-checkbox>
                </ng-container>
            </div>
            <div mat-button (click)="redirectMap()" *ngIf="unit_list_view" class="circle">
                <mat-icon>map</mat-icon>
            </div>
        </div>

        <div class="hgrid">
            <button *ngIf="unit_access.POST && select_import.length" mat-button (click)="openDialog('addUnit',{'gis':true,'bulk':true})" class="redbutton">
                <mat-icon id="import">add_box</mat-icon>
            </button>

            <button *ngIf="unit_access.DELETE && selected_unit_id.length == 1" mat-button (click)="openDialog('remove')" class="redbutton">
                <mat-icon id="delete" svgIcon="delete"></mat-icon>
            </button>
            <button *ngIf="unit_access.POST" [disabled]="unit_list_view"  (click)="openDialog('addUnit')" class="primary-btn  ovrd">
                Add Unit
                <mat-icon class="s24p">add</mat-icon>
            </button>
        </div>

    </div>

    <div *ngIf="floor && (selected_unit_id.length>0) && unit_access.POST && selected_floor.type == 'dummy'" class="textBox2">
        <span id="moveToFloor">Move to Floor</span>
    </div>
    <div *ngIf="floor && (selected_unit_id.length>0) && unit_access.POST" class="textBox">
        <span id="pasteInFloor">Paste in Floor</span>
    </div>
    <div *ngIf="floor && (selected_unit_id.length>0) && unit_access.POST" class="textBox1">
        <span id="pasteInFloor">Paste Here</span>
    </div>

    <div *ngIf="selected_unit_id.length>0 && unit_access.POST">
        <div *ngIf="floor" class="switch">
            <div *ngIf="restrictDummy(selected_floor.type)" class="icon-container1" matTooltip="Move to Floor">
                <mat-icon id="floor-icon-disabled">call_made</mat-icon>
            </div>
            <div *ngIf="restrictDummy(selected_floor.type)" (click)="openDialog('moveUnit')" class="icon-container1" matTooltip="Move to Floor">
                <mat-icon id="floor-icon">call_made</mat-icon>
            </div>
            <div (click)="openDialog('floorPasteUnit')" class="icon-container">
                <mat-icon id="floor-icon" svgIcon="floor"></mat-icon>
            </div>
            <div (click)="openDialog('floorPasteUnit',{same_floor:true})" class="icon-container">
                <mat-icon id="floor-icon" svgIcon="unit"></mat-icon>
            </div>
            <div></div>
        </div>
        <div class="floating animated bounceIn delay-2s">
            <div (click)="showFloorNUnit()" class="round">
                <div>
                    <mat-icon id="shuffle" svgIcon="shuffle"></mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="search">
        <div class="searchBtn">
            <div class="box">
                <div>
                    <input type="search" class="search-input" [formControl]="searchField" placeholder="Enter Unit UID">
                </div>
                <div id="mag" title="search">
                    <mat-icon class="magnify">search</mat-icon>
                </div>
            </div>
        </div>
        <ng-container>
            <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon class="applied" svgIcon="filter">
                        <div class="fixed-badge">
                            <i *ngIf="filterObj && filterObj.class"
                                class="material-icons {{filterObj.class}}">fiber_manual_record</i>
                            <img *ngIf="filterObj && filterObj.icon" class="all-select mr-1" [src]="filterObj.icon"
                                alt="All">
                        </div>
                    </mat-icon>
                </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let edit of Edited" class="align-c" (click)="onedited(edit)">
                        <i *ngIf="edit && edit.class" class="material-icons {{edit.class}}">fiber_manual_record</i>
                        <span>{{edit.name}}</span>
                    </button>
                <button mat-menu-item *ngFor="let st of statusFilters" class="align-c" (click)="filterUnitList(st)">
                        <i *ngIf="st && st.class" class="material-icons {{st.class}}">fiber_manual_record</i>
                        <img *ngIf="st && st.icon" class="all-select mr-1" [src]="st.icon" alt="All">
                        <span>{{st.name}}</span>
                    </button>
            </mat-menu>
        </ng-container>
    </div>

    <!-- <mat-tab-group [ngStyle]="{'height.px': newHeight}" (selectedTabChange)="onLinkClick($event)">
        <mat-tab label="Estater Units [{{estaterUnitCount}}]"> -->
            <div class="button-block-m">
                <div *ngIf="loader" class='loader-div'>
                    <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                    </ngx-loading>
                </div>
                <!-- quickedit loader start-->
                <div *ngIf="loading">
                    <ngx-loading [show]="loading" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                    </ngx-loading>
                </div>
                <!-- quickedit loader end -->
                <div class="mobile-block-unit scroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll()" [ngStyle]="{'height.px': blockHeight}">
                    <div *ngFor="let data of unitData">
                        <div class="big-button-m animated fadeIn delay-2s" id="unit_{{data.id}}" [class.red]="data.is_deleted == true">
                            <div id="image" [ngStyle]="{'background': 'linear-gradient(to top, black , transparent), url('+data.image+')', 'background-repeat':'no-repeat', 'background-size': 'cover'}">
                                <mat-checkbox (change)="onCheckStatus($event)" value="{{data.id}}" class="checkB" [checked]="ifChecked(data.id)"></mat-checkbox>
                                <span id="unit-id">{{data.uid}}</span>
                                <!-- <img id="i-web" src="{{data.image}}" alt="Apartment"> -->
                            </div>
                            <div id="data">
                                <div id="title" [ngClass]="{ 'rework_unit': data.status == 4, 'rework_unit_status': data.status == 6}">
                                    <div class="title1" *ngIf="data.uid">
                                        <p>{{data.unit_number}}</p>
                                    </div>
                                    <div [ngStyle]="{'color':'#fd7e14' }" *ngIf="!data.uid" class="title1">
                                        <strong>GIS</strong>
                                    </div>

                                    <div>{{getpropertypeLable(data.property_type_name)}}</div>
                                    <div (click)="changeVacant(data)" id="vacant_{{data.id}}">
                                        <mat-icon [ngClass]="{'filled': data.is_vacant}" id="filled" svgIcon="vacant">
                                        </mat-icon>
                                    </div>
                                </div>
                                <div (click)="openOptions(data,true)" id="bottom">
                                    <div id="one">
                                        <div>Unit Type</div>
                                        <div class="hfx">
                                            <strong>{{data.unit_type}}</strong>
                                        </div>
                                    </div>
                                    <div id="one">
                                        <div>Sale Price</div>
                                        <div class="hfx">
                                            <strong>{{data.currency}}{{data.sale_price}}</strong>
                                        </div>
                                    </div>
                                    <div id="one">
                                        <div>Lease Price</div>
                                        <div class="hfx">
                                            <strong>{{data.currency}}{{data.lease_price}}</strong>
                                        </div>
                                    </div>
                                    <div id="one">
                                        <div>Area</div>
                                        <div class="hfx" title="{{data.unit_area}}">
                                            <strong>{{data.unit_area}}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </mat-tab>
    </mat-tab-group> -->
</div>
