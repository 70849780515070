import { Component, OnInit, Inject, ElementRef, Output, EventEmitter } from '@angular/core';
import { ShareService } from '../../../services/share.service';
import { MatIconRegistry } from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiserviceService } from '../../../services/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { UntypedFormControl,Validator, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
// import { Options } from 'sortablejs';
// import { SortablejsOptions } from 'ngx-sortablejs';
import { debounceTime } from 'rxjs';
import * as $ from 'jquery';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import {MainBreadcrumbService} from '../../main-breadcrumb/main-breadcrumb.service';
import { QuickEditComponent } from '../../quick-edit/quick-edit.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';

export interface unit_list{
  direction : string,
  unit_type : string,
  currency : string,
  sale_price : number,
  lease_price : number,
  area : number,
  id: number,
  gid: number,
  property_type_id:number,
  uid:string,
  sort_order: number,
  image : any,
  unit_number:any,
  is_vacant:boolean,
  attribute_info:any,
  status: any
}
export interface property_type{
  name: string,
  label:string,
  id: number
}

 /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_entity_attributes_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_patch</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_patch</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_get</li>
 * <li>gisapi_units_json_get</li>
 * <li>estapi_propertytypes_get</li>
 * </ol>
 *
 */


@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit {
mobile: boolean = false;
dHeight:number;
floor : boolean = false;
group_custom_list_unit : any;
selected_property: string;
loader: boolean = false;
unit_limit: number = 25;
unit_offset: number = 0;
unitData: Array<unit_list> = [];
unitDataGis: Array<unit_list> = [];
selected_unit_id: Array<number>=[];
unit_detail:any;
selected_unit:any;
selected_floor:any;
unit_access:any;
select_import = [];
unit_list_view : boolean = false;
isMap : boolean = false;
newHeight: number;
blockHeight: number;
webHeight: number;
webBlock:number;
searchText: string;
searchField: UntypedFormControl;
estaterUnitCount:number;
gisUnitCount: number;
changeFilter:number = 0;
property_type_list:Array<property_type> = [];
assignment_detail:any;
rejectedUnitAttributeList:any[]=[];
loading:boolean = false;
flag: Number = 0;
allGisUnitList = [];
allEstUnitList = [];
dialogRef:any;

  //unit filter declarations===================================================================================
  statusFilters: any = [
    {status: '1', name: 'Created', class: 'red', icon: ''},
    {status: '4', name: 'Rework', class: 'blue', icon: ''},
    {status: '6', name: 'Editing', class: 'green', icon: ''},
    {status: '2', name: 'Review', class: 'orange', icon: ''},
    {status: '1,2,3,4,5,6,7', name: 'All', class: '', icon: './assets/images/selectAll.png'}
  ];
  Edited: any = [{ status: '1', name: 'Edited/Created', class: 'edited', icon: '' },
  // { status: '2', name: 'Fresh', class: 'fresh', icon: '' }
];

  _isEdited = 0;
  currentFilter:any = '1,2,3,4,5,6,7';
  filterObj:any = {status: '1,2,3,4,5,6,7', name: 'All', class: '', icon: './assets/images/selectAll.png'};
    //unit filter declarations===================================================================================

@Output() notify: EventEmitter<string> = new EventEmitter<string>();
  isFloorMap: boolean=false;
  isAttributeLoaded: boolean = false;
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private info:NotificationService,
    private share:ShareService, public dialog: MatDialog, private element: ElementRef,
    private location: Location, private fnc:CommonfunctionService, private route: Router,
    private api: ApiserviceService,
    private _crumbs : MainBreadcrumbService) {
    this.mobile = this.share.mobile;
    if(!this.share.block0_obj){
      this.route.navigateByUrl('/');
      return;
    }
    this.assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));
    this.unit_access = this.share.block0_obj.unit_access;
    this.share.unit_obj = this;
    this.selected_floor = this.share.floor_obj.selected_floor;
    this.estaterUnitCount = this.share.floor_obj.selected_floor.units;
    this.searchField = new UntypedFormControl();
    this.getNoOfUnits();

  iconRegistry.addSvgIcon('up',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/up_arrow.svg'))
              .addSvgIcon('down',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/down-arrow.svg'))
              .addSvgIcon('vacant',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/vacant.svg'))
              .addSvgIcon('delete',sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/delete.svg'))
              .addSvgIcon('edit',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Edit.svg'))
              .addSvgIcon('shuffle',sanitizer.bypassSecurityTrustResourceUrl('assets/images/Shuffle_arrow.svg'))
              .addSvgIcon('floor',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/bottom/Floor.svg'))
              .addSvgIcon('unit',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/bottom/unit.svg'))
              .addSvgIcon('tick',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Check.svg'));
    this.share.block0_obj.getAllowResource();
    setTimeout(()=>{
      this.unit_access = this.share.block0_obj.unit_access;
    },800);
  }
  restrictDummy(name){
    if(name){
      name = name.trim().toLowerCase();
      if(name == 'dummy'){
        return true;
      }else{
        return false;
      }
    }
  }

  public onedited (e){
    this._isEdited = e.status;
    this.currentFilter = '1';
    this.filterObj = e;
    this.getNoOfUnits()
    this.getUnitData();
  }

  filterUnitList(val){
    this.currentFilter = val.status;
    if(this.currentFilter == '1'){
      this._isEdited = 2;
    }else{
      this._isEdited = 0;
    }
    if(this.currentFilter == '1,2,3,4,5,6,7'){
      this._isEdited = 0;
    }
    this.filterObj = val;
    this.getNoOfUnits()
    this.getUnitData();
  }

  ngAfterViewInit(){
    // if(!this.mobile && this.isMap){
    //   this.redirectMap();
    // }
  }
  deleteUnit(id){

  }
  isVacantMessage(e){
    if(e){
      return 'Vacant unit';
    }else{
      return 'Unit occupied'
    }
  }
  changeVacant(data){
    if(!this.unit_access.PATCH){
      this.info.notify('You are not authorized to update unit','warn');
      return;
    }
    let unit_id = data.id;
    if(!unit_id){
      return;
    }
    let e = $(this.element.nativeElement);
    let index = this.fnc.getArrayindex('id',unit_id,this.unitData);
    let content = {};
    if(index >= 0){
      let vacant:boolean;
      if(data.is_vacant){
        vacant = false;
      }else{
        vacant = true;
      }
      e.find("#vacant_"+unit_id).addClass('disabled-div');
      let property_type_id = this.fnc.getArrayValue('name',data.property_type_name,this.property_type_list);
      let url = "deadmin/property_unit/attributes?sort_by=sort_order&limit=10000&offset=0&attribute_name=is_vacant&assignment_id="+this.share.block0_obj.assignment_id+"&property_type_ids="+property_type_id.id;
      this.loader = true;
      this.api.getEmsData(url)
      .subscribe((val:any)=>{
        this.loader = false ;
        val = val[0];
      if(val){
        let attr_id = val.attribute_id;
        content[attr_id] = vacant;
        let body = {
          data : {
            '0':content,
            '1':{}
          },
          "updated_by": this.api.user_id
        };
        let url = 'deadmin/'+this.assignment_detail.assignment_name+'/properties/'+this.share.block0_obj.selected_property.id+"/floors/"+this.share.floor_obj.selected_floor_id+"/units/"+unit_id;
        this.api.patchEpsData(url,body)
        .subscribe((data:any) =>{
          this.unitData[index].is_vacant = vacant;
          e.find("#vacant_"+unit_id).removeClass('disabled-div');
        });
      }
      });
    }
  }
  showFloorNUnit(){
    if(this.floor == false){
      this.floor = true;
    }
    else{
      this.floor = false;
    }
  }
// unitoptions: SortablejsOptions = {
//   draggable: '.big-button-m',
//   onUpdate: (event:any) => this.sortWebFloor(event)
// };

sortWebFloor(event){
  if(event.previousIndex == event.currentIndex){
    return;
  }
  moveItemInArray(this.unitData, event.previousIndex, event.currentIndex);
  let isValid=false;
  // let unitDetail, secondUnit ,temp;

  //   unitDetail = this.unitData[event.currentIndex];
  //   secondUnit = this.unitData[event.previousIndex];

  //   temp = secondUnit.sort_order;

  //   secondUnit.sort_order = unitDetail.sort_order;
  //   unitDetail.sort_order = temp;

    // let i, l, k = 0

    // for (i = 1; i < this.unitData.length; i++) {
    //   k = this.unitData[i].sort_order;
    //   l = i;
    //  while (l > 0 && this.unitData[l - 1].sort_order > k) {
    //     this.unitData[l].sort_order = this.unitData[l - 1].sort_order;
    //     l--;
    //   };
    //   this.unitData[l].sort_order = k;

    // }
    isValid = true;


  if(isValid){
    let body=[];
    this.unitData.map((v,k)=>{
     body.push({
      "sort_order": k,
        "unit_id": v.id,
        "updated_by": this.api.user_id
     })

    })

    // let body = [
    //   {
    //     "sort_order": unitDetail.sort_order,
    //     "unit_id": unitDetail.id,
    //     "updated_by": this.api.user_id
    //   },
    //   {
    //     "sort_order": secondUnit.sort_order,
    //     "unit_id": secondUnit.id,
    //     "updated_by": this.api.user_id
    //   }
    // ];

    this.api.patchEpsData("deadmin/"+this.assignment_detail.assignment_name+"/properties/" + this.share.block0_obj.selected_property.id + '/floors/'+this.share.floor_obj.selected_floor_id+'/units', body)
    .subscribe(
      {
        next: (data:any) => {},
        error:(err:any) => {
        }
      });
  }
}
isOn(v){
  if(v.id && this.selected_unit){
    if(this.selected_unit.id == v.id) return true
  }else{
    return false;
  }
}
openOptions(v: any, mobile: boolean = false): void {
  //console.log(v);

  if(!v) return;
  this.selected_unit = v;
  this.share.selected_obj_uid  = '';
  this.rejectedUnitAttributeList = [];
  if(v.attribute_info != null){
    for(let key in v.attribute_info){
     if (v.attribute_info[key].status == -1 || (v.attribute_info[key].status == 3 && v.status == 6)){ // -1 is rejected, 3 edited by user.
      let d = {
        "keyId": Number(key),
        "status" : v.attribute_info[key].status,
        "comment" : v.attribute_info[key].comment
      }
      this.rejectedUnitAttributeList.push(d);
     }
    }
  }

  if(mobile == false){
    if(this.share.block3_obj){
      this.share.block3_obj.submitForm();
    }
  }

  if(!v.uid){
    this.openDialog('addUnit',{'gis':true});
    return;
  }
  let property_type_id = this.fnc.getArrayValue('name',v.property_type_name,this.property_type_list);
  this.share.block0_obj.property_type_id = property_type_id.id;
  this.getGroups();
  this.getUnitDetail();
  this.share.selected_obj_uid = this.selected_unit.uid;
}


getGroups(){
  this.share.block0_obj.entity_type="property_unit";
  this.share.block0_obj.getGroups();
  this.getUnitAttributes(); // grtting units attributes
}
getUnitDetail(){
  this.api.getEpsData("deadmin/"+this.assignment_detail.assignment_name+"/properties/"+this.share.block0_obj.selected_property.id+'/floors/'+this.share.floor_obj.selected_floor_id+'/units/'+this.selected_unit.id)
  .subscribe((data:any) => {
    this.unit_detail = data;
    if(this.api.language == this.api.form_language || this.api.form_language == undefined){
      localStorage.setItem('unit_detail',JSON.stringify(this.unit_detail));
    }
  })
}

getUnitData(is_scroll=false){
  let url;
  this.selected_unit_id = [];
  if(!this.unit_access.GET){
    this.info.notify('You are not authorized to access unit','warn');
    return;
  }
  this.loader = true;
  if(!is_scroll){
    this.unitData = [];
    this.unit_offset = 0;
    if(this.share.dynamic_obj){
      this.share.dynamic_obj.unitGroup = false;
      this.share.dynamic_obj.unitSubgroup = false;
      this.share.dynamic_obj.form = false;
    }
  }

  if(this.searchText){
    url = "deadmin/"+this.assignment_detail.assignment_name+"/properties/"+this.share.block0_obj.selected_property.id+'/floors/'+this.share.floor_obj.selected_floor_id+'/units?unit_uid='+this.searchText+'&attribute_value='+this.searchText+'&offset='+this.unit_offset+'&limit='+this.unit_limit+'&order_by=sort_order&return_measure_units=true&return_attribute_ids=false';
  }else{
    url = "deadmin/"+this.assignment_detail.assignment_name+"/properties/"+this.share.block0_obj.selected_property.id+'/floors/'+this.share.floor_obj.selected_floor_id+'/units?offset='+this.unit_offset+'&limit='+this.unit_limit+'&order_by=sort_order&return_measure_units=true&return_attribute_ids=false';
  }
  if (this.currentFilter) {
    url += '&status=' + this.currentFilter;
   }
  if(this._isEdited !== 0){
    url += '&get_edited='+this._isEdited;
  }
  url += `&get_latest_property=Y`;
   this.api.getEpsData(url)
   .subscribe({
    next: (data:any) =>{
      this.loader = false;
      let s_price,l_price,area,img_list,img_url,image,unit_no,vacant;
      let is_deleted = false;
      if(!data.length) return;
      data.forEach(e => {
        s_price = null;l_price = null,area=null,img_url ="../../../assets/images/building_icon.png",unit_no="NA";
        vacant = true;
        if(e.data.sale_price){
          s_price = e.data.sale_price;
        }
        if(e.data.lease_price){
          l_price = e.data.lease_price;
        }
        if(e.data.is_deleted){
          is_deleted = e.data.is_deleted;
        }else{
          is_deleted =false
        }
        if(e.data.unit_area){
          let l,k,j;
          l = e.data.unit_area.split(" ");
          k = Number(l[0]).toFixed(2);
          j = l[1];
          area = k+' '+j;
        }
        //if(e.data.is_vacant !== undefined){
          vacant = e.data.is_vacant;
        //}

        if(e.data.unit_number){
          unit_no = e.data.unit_number;
        }
        if(e.data.images){
          img_list = e.data.images;
          if(img_list.length > 0){
            img_url = img_list[0][0];
            image = this.fnc.getArrayValue('2',true,img_list); //get default property image
            if(image){
              img_url = image[0];
            }
          }
        }
        let fdata:any = {
          is_deleted: is_deleted,
          direction : null,
          unit_type : e.unit_type_label,
          currency : e.currency,
          sale_price: s_price || 0,
          lease_price: l_price || 0,
          unit_area: area || 0,
          id: e.unit_id,
          uid: e.unit_uid,
          gid : e.unit_gis_uid,
          unit_type_name : e.unit_type_name,
          sort_order : e.sort_order,
          image : img_url,
          unit_number : unit_no,
          is_vacant : vacant,
          property_type_name:e.property_type_name,
          property_type_id:e.property_type_id,
          attribute_info:e.attribute_info,
          status:e.status
        }
        this.unitData.push(fdata);
      });
      this._crumbs.count = this.unitData.length;

   },
  error:(err) => {
    this.loader = false;
  }}
  )
 }

/**
 * Remove gis api
 */
// getGisUnitData(){
//   if(!this.unit_access.GET){
//     this.info.notify('You are not authorized to access unit','warn');
//     return;
//   }
//   let url;
//   this.selected_unit_id = [];
//   this.allGisUnitList = [];
//   url =  'units/json?floor_uid='+this.share.floor_obj.selected_floor.gid;
//    this.api.getGesData(url)

//    .subscribe(
//      (data:any) =>{
//        if(data){
//         let unitList = [];
//          data.forEach((unit) => {
//             unit['sub_category'] = unit['sub_category'] ? unit['sub_category'] : '';
//             unitList.push(unit);
//           });
//         this.allGisUnitList = unitList;
//        }
//        this.checkForExistingGisBuilding();
//    },
//     error:() => {
//       if (err['status'] == 401) {
//         this.info.notify('You are not authorize for GIS','error')
//        }
//       this.checkForExistingGisBuilding();
//     }
//   )
//  }

checkForExistingGisBuilding() {
  this.unitDataGis = [];
  if (this.allGisUnitList.length) {
    let s_price, l_price, currency, area, img_url, unit_no;
    s_price = null; l_price = null, currency = null, area = null, img_url = "../../../assets/images/building_icon.png", unit_no = "NA";
    this.allGisUnitList.forEach((e) => {
      this.loader = false;
      let res :any=null;
      if(this.allEstUnitList.length){
        res = this.allEstUnitList.filter(ele=> e.unit_uid ==  ele.unit_gis_uid)
      }
      if(res) res=res[0];
      //  = this.fnc.getArrayValue('unit_gis_uid', e.unit_uid, this.allEstUnitList);
      if (res == null || res == undefined) {
        if (e.unit_number) {
          unit_no = e.unit_number;
        }
        if (e.unit_area) {
          area = e.unit_area;
        }
        let gUdata: any = {
          area: area,
          currency: currency,
          unit_type_name:e.sub_category,
          property_type_name: e.category,
          sale_price: s_price,
          lease_price: l_price,
          id: e.unit_id,
          gid: e.unit_uid,
          sort_order: e.sort_order,
          image: img_url,
          unit_number: unit_no,
          status: e.status
        }
        this.unitDataGis.push(gUdata);

        if (this.unitDataGis.length && this.currentFilter == '1,2,3,4,5,6,7' && this._isEdited == 0) {
          $(".mat-tab-header").css('display', 'flex');
          $(".mat-tab-label").css('font-size', '12px');
        } else {
          $(".mat-tab-header").css('display', 'none');
        }

      }
    }, err=>{
      this.loader = false;
    });

  }
}
 getpropertypeLable(val){
  const unitName = this.fnc.getArrayValue('name',val,this.property_type_list);
   if (unitName) {
     return unitName.label;
   } else {
     return val;
   }
 }

 onScroll(){
  this.unit_offset += this.unit_limit;
  this.getUnitData(true);
}

 getUnitAttributes(redirect=true){
  this.isAttributeLoaded = true;
  this.share.updateCurrentState(this.isAttributeLoaded);
  this.share.block0_obj.entity_type = "property_unit";
  this.share.block0_obj.getAttributes()
  .subscribe((data:any) =>{
    this.isAttributeLoaded = false;
    this.share.updateCurrentState(this.isAttributeLoaded);
    let d = this.fnc.getChildArray('quick_edit', true, this.share.block0_obj.attribute_custom_list_unit);
    if(d.length > 0 && !this.isFloorMap && this.selected_unit) {
      if (!this.mobile) { this.notify.emit('unitGroup'); }
      if(this.dialogRef) return;
       this.dialogRef = this.dialog.open(QuickEditComponent, {
        width: '450px',
        maxWidth: '95vw',
        maxHeight: '95vh',
        panelClass: 'create-master-panel',
        data: this
      });
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    }
    // else {
    //   if (this.isMap && d.length > 0 && this.share.unit_obj.selected_unit) {
    //     console.log(this.share.toolbox_obj?.flagVal);

    //     this.flag = this.share.toolbox_obj?.flagVal;
    //     console.log(this.flag);

    //     if (this.flag == 1 || this.flag == 2) {
    //       this.notify.emit('unitGroup');
    //       if (this.mobile) {
    //         this.route.navigate(['unitGroup']);
    //       }
    //       // if (!this.mobile) { this.notify.emit('unitGroup'); }
    //       let dialogRef = this.dialog.open(QuickEditComponent, {
    //         width: '450px',
    //         maxWidth: '95vw',
    //         maxHeight: '95vh',
    //         panelClass: 'create-master-panel',
    //         data: this
    //       });
    //       dialogRef.afterClosed().subscribe(result => {
    //       });
    //     }else{
    //       this.notify.emit('unitGroup');
    //       if (this.mobile) {
    //         this.route.navigate(['unitGroup']);
    //       }
    //     }
    //   } else {
        if (redirect) {
          this.notify.emit('unitGroup');
          if (this.mobile) {
            this.route.navigate(['unitGroup']);
          }
        }
    //   }
    // }

  },err=>{this.loading = false; this.isAttributeLoaded = false; this.share.updateCurrentState(this.isAttributeLoaded);});

}

getNoOfUnits(){
  let url;
  url = "deadmin/"+this.assignment_detail.assignment_name+"/properties/"+this.share.block0_obj.selected_property.id+'/floors/'+this.share.floor_obj.selected_floor_id+'/units?status=' + this.currentFilter;
  if(this._isEdited !== 0){
    url += '&get_edited='+this._isEdited;
  }
  url += `&get_latest_property=Y`;
  this.api.getEpsData(url)
  .subscribe(
    {next: (data:any) =>{
      if(data){
        this.allEstUnitList = data;
      }
      this.estaterUnitCount = data.length;
      this._crumbs.count = this.estaterUnitCount;
      this._crumbs.title = 'Units';

      if(this.currentFilter == '1,2,3,4,5,6,7' && this._isEdited == 0){
        //this.getGisUnitData(); // Remove Gis API calling for units
      }

    },
    error: (err)=>{
      this.estaterUnitCount = 0;
      this._crumbs.count = 0;
      this._crumbs.title = 'Units';
      if(this.currentFilter == '1,2,3,4,5,6,7' && this._isEdited == 0){
        //this.getGisUnitData(); // Remove Gis API calling for units
      }
    }})

}
sortOrder(action) {
  if (this.selected_unit_id.length == 1) {
    let unit_id = this.selected_unit_id[0];
    let currentOrder,currentSerial,secondUnit,isValid=false;
    let index = this.fnc.getArrayindex('id', unit_id, this.unitData);
    let unitDetail = this.unitData[index];
    if(unitDetail){
        if(action == 'up'){
          secondUnit = this.unitData[index-1];
          currentOrder = unitDetail.sort_order;
          unitDetail.sort_order = secondUnit.sort_order;
          secondUnit.sort_order = currentOrder;
          this.unitData[index-1] = unitDetail;
          this.unitData[index] = secondUnit;
          isValid = true;
        }
        if(action == 'down'){
          secondUnit = this.unitData[index+1];
          currentOrder = unitDetail.sort_order;
          unitDetail.sort_order = secondUnit.sort_order;
          secondUnit.sort_order = currentOrder;
          this.unitData[index+1] = unitDetail;
          this.unitData[index] = secondUnit;
          isValid = true;
        }
    }

    if(isValid){
      let body = [
        {
          "sort_order": unitDetail.sort_order,
          "unit_id": unitDetail.id,
          "updated_by": this.api.user_id
        },
        {
          "sort_order": secondUnit.sort_order,
          "unit_id": secondUnit.id,
          "updated_by": this.api.user_id
        }
      ];

      this.api.patchEpsData("deadmin/"+this.assignment_detail.assignment_name+"/properties/" + this.share.block0_obj.selected_property.id + '/floors/'+this.share.floor_obj.selected_floor_id+'/units', body)
      .subscribe({
        next:(data:any) => {},
        error:(err) => {
        }
      });
    }
  }
}

  onCheckStatus(e){
    if(e.checked){
      this.selected_unit_id.push(+e.source.value);
    }else{
      this.selected_unit_id.splice(this.selected_unit_id.indexOf(+e.source.value),1);
    }
  }
  onLinkClick(e){
      this.changeFilter = e.index;
      (this.changeFilter);

  }
  selectAllGisUnit(e){
    if(e.checked){
      this.unitDataGis.forEach((v)=>{
        this.select_import.push(v.gid);
      })
    }else{
      this.select_import = [];
    }
  }
  selectAllUnit(e){
    if(e.checked){
      this.unitData.forEach((v)=>{
        this.selected_unit_id.push(v.id);
      })
    }else{
      this.selected_unit_id = [];
    }
  }

  ifChecked(data){
    let flag = false;
      if(this.selected_unit_id){
        if(this.selected_unit_id.includes(data)){
          flag = true;
        }
      }
    return flag;
  }
  ifGisChecked(data){
    let flag = false;
    if(this.select_import){
      if(this.select_import.includes(data)){
        flag = true;
      }
    }
    return flag;
  }

  onCheckImport(e){
    if(e.checked){
      this.select_import.push(e.source.value);
    }else{
      this.select_import.splice(this.select_import.indexOf(e.source.value),1);
    }
  }


  backClicked(){
    //this.location.back();
    this.route.navigateByUrl('floorGroup');
  }
   // getting list of property type
   getPropertyType(){
    let url = "propertytypes?status=1";
    this.api.getEmsData(url)
    .subscribe(
      (data:any) => {
        let name,label='';
        data.forEach((v) => {
          name = v.property_type_name;
          label = v.property_type_name;
          if(v.property_type_label[this.api.language]){
            label = v.property_type_label[this.api.language];
          }
          this.property_type_list.push({
            name: name,
            label: label,
            id: v.property_type_id
          });
        })
      }
    );
  }

  getUnitJSON(){
    this.api.getEpsData(`/deadmin/${this.assignment_detail.assignment_name}/properties/${this.share.block0_obj.selected_property.id}/floors/${this.share.floor_obj.selected_floor_id}/units-geojson?get_latest_property=Y`)
    .subscribe({
      next: (data:any)=>{
        if(data && data.data){
          let geom_data:any= data.data;

          if(geom_data.hasOwnProperty("features") && geom_data.features.length > 0){

            for(let i=0; i<geom_data.features.length ; i++){
              if(geom_data.features[i].geometry){
                this.isMap = true;
                break;
              }
            }
          }
        }
      }, error:() =>{

      }
    }
    )
  }
  ngOnInit() {
    let unit_list_obj = this.share.floor_obj.unit_list;
    // open floor map if floor geom available
    // if (this.share.floor_obj.floor_map){
    //   this.isMap = true;
    // }
    if(!this.mobile){
      this.getUnitJSON();
    }
    if(unit_list_obj == true){
      this.unit_list_view = true;
    }
    this.unitData = [];
    this.unitDataGis = [];
    this.getUnitData();
    this.getPropertyType();
    this.selected_property = this.share.block0_obj.selected_property;

  if(this.mobile){
    if(this.share.floor_obj.floor_map && this.unit_list_view == false){
      this.route.navigateByUrl('/floor/map');
      return;
    }
  }
    setTimeout(() => {
      $(".mat-tab-header").css('display','none');
    }, 5);
    setTimeout(()=>{
      // this.dHeight = this.share.heightService;
      // let e = $(this.element.nativeElement);
      // this.newHeight = (this.dHeight-56);
      // this.blockHeight = (this.dHeight-126);
      // e.find('#D-height_g').css('height',(this.dHeight-56));
      // e.find('.D-height').css('height',(this.dHeight-56));
      // e.find('#DynamicBlock').css('height',(this.dHeight-106));
      // this.webHeight = (this.dHeight-106);
      // this.webBlock = (this.dHeight-191);
    }, 10);
    this.searchField.valueChanges
      .pipe(debounceTime(1500))
      .subscribe(term => {
        this.searchText = term;
        this.getUnitData(false);
      })
  }

  redirectMap(){
    if(this.mobile == false){
      if(this.share.dynamic_obj.form){
        this.share.block3_obj.submitForm();
      }
    }
    let data = this.share.floor_obj.selected_floor;
    if(this.mobile){
      this.unit_list_view = false;
      if(this.share.floor_obj.floor_map && this.unit_list_view == false){
        this.route.navigateByUrl('/floor/map');
      }
    }else{
      this.share.floor_obj.openFloorMap(data);
    }

  }
  openDialog(type : string, info:any = {}): void {
    let dialogview;
    info.pObj = this;
    if(type == 'addUnit') {
      dialogview = AddUnitComponent
    }
    else if(type == 'floorPasteUnit'){
      dialogview = FloorPasteUnitComponent;
      if(info.same_floor) this.showFloorNUnit(); // closing button in case of paste in same floor
    } else if(type == 'remove'){
      dialogview = ConfirmRemove;
    }
    if(type == 'moveUnit'){
      dialogview = FloorPasteUnitComponent;
      info.move = true;
    }
    if(dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: '420px',
        maxWidth:'92vw',
        panelClass : 'create-master-panel',
        data: info
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

}

@Component({
  selector: 'addUnit',
  templateUrl: './addUnit.component.html',
  styleUrls: ['./unit.component.css']
})

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_entity_masters_master_id_values_get</li>
 * <li>estapi_masters_get</li>
 * <li>estapi_attribute_measure_unit_type_measureunits_get</li>
 * <li>estapi_attribute_measureunittypes_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_post</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units2_post</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_unit_post</li>
 * </ol>
 *
 */

export class AddUnitComponent{
  gis = false;
  property_type_list:Array<property_type> = [];
  addUnitForm : UntypedFormGroup;
  addUnitForm_valueset : Array<{}>=[];
  values : Array<any> = [];
  measure_unit_type: any = [];
  unit_type_list:any = [];
  field_count = {};
  property_type:any;
  master_id:number;
  err_msg:string = '';
  loader: boolean=false;
  constructor(public dialogRef: MatDialogRef<AddUnitComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private share: ShareService, private api: ApiserviceService, private _fb : UntypedFormBuilder, private notify: NotificationService){
    //this.getMeasureUnitType();
    this.getUnitByType('area');
    this.property_type_list = this.data.pObj.property_type_list;
    if(this.data.gis) this.gis = this.data.gis;

  }

  createForm(){
    let attr = {};
    if(this.unit_type_list.length > 0){
      this.unit_type_list.forEach((v)=>{
        attr['input_'+v.id] = this._fb.array([]);
        this.field_count['count_'+v.id] = [];
      });
      this.addUnitForm = this._fb.group(attr);
      this.unit_type_list.forEach((v)=>{ // initializing each value
        this.addRow(v);
      });
    }

  }
  getUnitMasterVal(){
    this.unit_type_list = [];
    if(!this.master_id){
    this.api.getEmsData('masters?master_name=unit_types&limit=1000')
    .subscribe((data:any) =>{
      this.master_id = data[0].master_id;
      this.api.getEmsData('property_unit/masters/'+this.master_id+'/values?limit=1000&status=1&property_type_ids='+this.property_type+'&sort_asc=true&sort_by=master_value_name')
      .subscribe((val:any) =>{
        (val);
        let result = {};
        val.forEach((v)=>{
          if(v.master_value_name != 'not_retail' && v.master_value_name != 'path'){
          result = {
            id: v.master_value_id,
            label : v.master_value_label[this.api.language],
            name : v.master_value_name,
            master_id : v.master_id,
            status : v.status,
            property_type_id : v.property_type_id
          }
          this.unit_type_list.push(result);
        }
        });
        this.createForm();
      });
    });
  }else{
    this.api.getEmsData('property_unit/masters/'+this.master_id+'/values?limit=1000&status=1&property_type_ids='+this.property_type+'&sort_asc=true&sort_by=master_value_name')
      .subscribe((val:any) =>{
        let result = {};
        val.forEach((v)=>{
          result = {
            id: v.master_value_id,
            label : v.master_value_label[this.api.language],
            name : v.master_value_name,
            master_id : v.master_id,
            status : v.status,
            property_type_id : v.property_type_id
          }
          this.unit_type_list.push(result);
        });
        this.createForm();
      });
  }
  }
  getFieldCount(id){
    return this.field_count['count_'+id];
  }
  addRow(v) {
    let dd = <UntypedFormArray> this.addUnitForm.controls['input_'+v.id];
    let field = {};
    field['number_'+v.id] = [];
    field['area_'+v.id] = [];
    field['unit_'+v.id] = [];
    dd.push(this._fb.group(field));
    this.field_count['count_'+v.id].push(field);
  }
  removeRow(v){
    if(this.field_count['count_'+v.id].length > 1){
      this.field_count['count_'+v.id].pop();
    }
  }
  getUnitByType(val){
    if(val){
      this.api.getEmsData("attribute/"+val+"/measureunits")
      .subscribe(
        (data:any) =>{
         this.measure_unit_type = data;
         (this.measure_unit_type);
        }
      );
    }
  }
  getMeasureUnitType(){
    let url ="attribute/measureunittypes";
    this.api.getEmsData(url).subscribe(
     {
      next: (data:any) => {
        data.forEach((v)=>{
         this.getUnitByType(v);
        });
      },
      error:()=>{},
     }
    );
  }

  addUnit(e){
    let unitnum = 0;
    // if(this.currentNo<0 && this.currentArea<0){
    //   this.data.pObj.info.notify('Negative values are not allowed','Dismiss');
    //   return;
    // }
    if(!this.data.pObj.unit_access.POST){
      this.data.pObj.info.notify('You are not authorized to create unit','warn');
      return;
    }
    this.err_msg = '';
    let val = e.value,x;
    let value = [],unit_count=[];
    for(x in val){
      val[x].forEach((v)=>{
        value.push(v);
      });
    }
    if(value.length){
      let attr,id,area,unit,is_complete=true;
      let unit_data = {
        count:null,
        unit_type_area:null,
        unit_type_name:null
      };
      value.forEach((v)=>{
        is_complete = true;
        unit_data = {
          count:null,
          unit_type_area:null,
          unit_type_name:null
        };
       for(x in v){
         if(!v[x]){
          is_complete = false;
         }
        attr = x.split('_');
        if(attr[0] == 'number') unit_data.count = v[x];
        if(attr[0] == 'area') area = v[x];
        if(attr[0] == 'unit') unit = v[x];
       };
       unitnum += unit_data.count;
       unit_data.unit_type_area = area + " "+ unit;
       unit_data.unit_type_name = this.data.pObj.fnc.getArrayValue('id',attr[1],this.unit_type_list)['name'];
       if(is_complete && unit_data.unit_type_name){
        unit_count.push(unit_data);
       }
      });
    }

    if(unit_count.length > 0){
      if(unitnum > 20){
        this.notify.notify('Maximum value is 20 for a single request', 'warn');
        return;
      }
      let body = {
        "added_by": this.api.user_id,
        "property_type_name": this.data.pObj.fnc.getArrayValue('id',this.property_type,this.property_type_list)['name'],
        "unit_type_counts": unit_count,
        "status": 1
      };

      let assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));
        this.api.postEpsData("deadmin/"+assignment_detail.assignment_name+"/properties/"+this.share.block0_obj.selected_property.id+'/floors/'+this.share.floor_obj.selected_floor_id+'/units', body)
        .subscribe({next: (data:any) =>{
          this.updateAttributeChange(this.share.block0_obj.selected_property, this.data.pObj.assignment_detail.assignment_name, data);
          this.data.pObj.getUnitData();
          this.data.pObj.getNoOfUnits();
        },
      error:(err) =>{
        // this.notify.notify(err.error.message, 'error');
      }});
      this.dialogRef.close();
    }else{
      this.err_msg = "Please fill atleast one complete row";

    }
  }

  //function to make newly created units identified by QAQC
  updateAttributeChange(selected_property,assignment_name,unit_data) {
    if (!selected_property) {
      return;
    }
    if (selected_property.status == 4 || selected_property.status == 6){
      let new_attr_info = {new_unit_ids: []};
      if (selected_property.attribute_info) {
        new_attr_info = selected_property.attribute_info;
        if(new_attr_info && new_attr_info.new_unit_ids){
          new_attr_info["new_unit_ids"] = [...new_attr_info.new_unit_ids, ...unit_data.unit_ids]
        }else{
          new_attr_info["new_unit_ids"] = unit_data.unit_ids;
        }
      }else{
        new_attr_info[ "new_unit_ids"] = unit_data.unit_ids;
      }
    let url = "deadmin/" + assignment_name + "/properties/" + selected_property.id;
    let body = {
      "updated_by": this.api.user_id
    }
    body["attribute_info"] = new_attr_info;
    this.api.patchEpsData(url, body).subscribe((data: any) => {
    })
    }
  }

  importBulkUnit(){
    let unit_detail = null,body=[];
    let import_unit = this.data.pObj.select_import;
    if(import_unit.length){
      import_unit.forEach(v=>{
        unit_detail = null;
        unit_detail = this.data.pObj.fnc.getArrayValue('gid',v,this.data.pObj.unitDataGis);
        if(unit_detail){
          body.push({
            "added_by": this.api.user_id,
            "property_type_name": unit_detail.property_type_name,
            "unit_type_name": unit_detail.unit_type_name,
            "unit_area" : unit_detail.area,
            "unit_gis_uid":unit_detail.gid,
            "status": 1,
            "unit_number":unit_detail.unit_number
          });
        }
      });
    }

    if(body.length){
      this.loader=true;
      this.api.postEpsData("deadmin/"+this.data.pObj.assignment_detail.assignment_name+"/properties/"+this.share.block0_obj.selected_property.id+'/floors/'+this.share.floor_obj.selected_floor_id+'/units2', body)
    .subscribe((data:any) =>{
      this.loader=false;
      this.data.pObj.info.notify('Units Successfully Imported','success');
      this.data.pObj.getNoOfUnits();
      this.data.pObj.select_import = [];
      this.data.pObj.unitDataGis = [];
      this.data.pObj.getUnitData();
      this.closeDialog();
      },
      err=>{
        this.loader=false;
        // this.notify.notify(err.error.message, 'error');
      });
    }

  }
  createGisUnit(){
    if(!this.data.pObj.unit_access.POST){
      this.data.pObj.info.notify('You are not authorized to create unit','warn');
      return;
    }
    if(this.data.pObj.select_import.length>0){
      this.importBulkUnit();
      return;
    }
    let selected_unit = this.data.pObj.selected_unit;
    let body = {
      "added_by": this.api.user_id,
      "property_type_name": selected_unit.property_type_name,
      "unit_type_name": selected_unit.unit_type_name,
      "unit_area" : selected_unit.area,
      "unit_gis_uid":selected_unit.gid,
      "unit_number":selected_unit.unit_number,
      "status": 1
    };
    this.loader=true;
    this.api.postEpsData("deadmin/"+this.data.pObj.assignment_detail.assignment_name+"/properties/"+this.share.block0_obj.selected_property.id+'/floors/'+this.share.floor_obj.selected_floor_id+'/unit', body)
    .subscribe({
      next: (data:any) =>{
      this.loader=false;
      this.data.pObj.select_import = [];
      this.data.pObj.unitDataGis = [];
      this.data.pObj.getUnitData();
      this.data.pObj.getNoOfUnits();
      this.closeDialog();
    },
  error:(err) =>{
    this.loader=false;
    // this.notify.notify(err.error.message, 'error');
  }});

  }

  ngOnInit(){
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  saveDialog(): void {
    //save information here
    this.dialogRef.close();
  }
}

 /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_copy_post</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_reallocate_patch</li>
 * <li>gisapi_units_put</li>
 * </ol>
 *
 */

@Component({
  selector: 'floorPasteUnit',
  templateUrl: './floorPasteUnit.component.html',
  styleUrls: ['./unit.component.css']
})
export class FloorPasteUnitComponent {
  mobile : boolean = false;
  count = 1;
  loader = false;
  selected_unit_ids:any;
  enableConfirm:boolean = false;
  selected_floor_ids:any = [];
  selected_floor_id:any;
  floorData:Array<{}> = [];
  assignment_detail: any;
  moveunit:boolean = false;
  selectedFloor:any;
  confirmation:boolean = false;
  constructor(private share:ShareService,
    public dialogRef: MatDialogRef<FloorPasteUnitComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private api:ApiserviceService,
    private notify:NotificationService,
    private fnc: CommonfunctionService){
    this.moveunit = data.move;
    this.mobile = this.share.mobile;
    this.selected_unit_ids = this.data.pObj.selected_unit_id;
    this.assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));
  }
  ngOnInit(){
    if(this.data.same_floor){
      this.pasteHere();
    }else{
      this.getFloorData();
    }
  }
  saveDialog(): void {
    //save information here
    this.dialogRef.close();
  }
  close(){
    this.dialogRef.close();
  }
   selectFloor(data){
     let id = data.id;
    if(this.selected_floor_ids.indexOf(id) == -1){
      if(!this.moveunit){
        this.selected_floor_ids.push(id);
      }else{
        this.selected_floor_id = id;
        this.selectedFloor = data;
      }
    }else{
      if(!this.moveunit) this.selected_floor_ids.splice(this.selected_floor_ids.indexOf(id),1);
    }
    if(!this.moveunit){
      if(this.selected_floor_ids.length>0){
        this.enableConfirm = true;
      }else{
        this.enableConfirm = false;
      }
    }else{
      if(this.selected_floor_id) this.enableConfirm = true;
    }
  }

  isSelected(id){
    if(!this.moveunit){
      if(this.selected_floor_ids.indexOf(id) != -1){
        return true;
      }
    }else{
      if(this.selected_floor_id == id){
        return true;
      }
    }

  }

   getFloorData(is_scroll = false) {
      this.floorData = [];
      this.loader=true;
      let parking;
    this.api.getEpsData("deadmin/"+this.assignment_detail.assignment_name+"/properties/" + this.share.block0_obj.selected_property.id + '/floors?get_latest_property=Y')
      .subscribe(
        {
          next: (data:any) => {
          this.loader = false;
          data.forEach(e => {
            parking = 0;
            if (e.data) {
              if (e.data.parking){
                let num = 0;
                  e.data.parking.forEach(ele => {
                      num += ele.parking_value;
                  });
                  parking = num;
              }
            }
            let fdata: any = {
              serial: this.count,
              sort_order: e.sort_order,
              uid: e.floor_uid,
              gid:e.floor_gis_uid,
              type: e.floor_name,
              units: e.no_of_units,
              parking: parking,
              id: e.floor_id
            }
            if(e.floor_id != this.share.floor_obj.selected_floor_id){ // exclude current floor from floor list
              this.floorData.push(fdata);
            }
            this.count++;
          });

        },
        error:() => {
          this.floorData = [];
          this.loader = false;
        }}
      )
  }

  confirm(flag:string){
    if(flag == 'YES'){
      this.copyUnit();
    }else{
      this.confirmation = false
      this.dialogRef.close();
      return;
    }
  }
  copyUnit(){
    if((this.selected_unit_ids.length && this.selected_floor_ids.length) || (this.selected_unit_ids.length && this.selected_floor_id)){
      let unit = false, body = {}, url;
      if(!this.moveunit){
        if(this.selected_unit_ids.indexOf(0) != -1){
          unit = true;
          this.selected_unit_ids.splice(this.selected_unit_ids.indexOf(0),1);
        }
        //if(this.selected_group_ids.length == 0) this.selected_group_ids = null;
        body = {
          "added_by": this.api.user_id,
          "source_unit_ids": this.selected_unit_ids,
          "target_floor_ids": this.selected_floor_ids
        };
        url = "deadmin/"+this.assignment_detail.assignment_name+"/properties/" + this.share.block0_obj.selected_property.id + '/floors/'+this.share.floor_obj.selected_floor_id+'/units/copy';
        this.api.postEpsData(url,body)
        .subscribe((data:any) =>{
          this.notify.notify('Unit copied','success');
          if(this.data.same_floor){
            this.data.pObj.getUnitData();
          }
        });
      }else{
        body = {
          "added_by": this.api.user_id,
          "source_unit_ids": this.selected_unit_ids,
          "target_floor_id": this.selected_floor_id
        };
        url = "deadmin/"+this.assignment_detail.assignment_name+"/properties/" + this.share.block0_obj.selected_property.id + '/floors/'+this.share.floor_obj.selected_floor_id+'/units/reallocate';
        this.api.patchEpsData(url,body)
        .subscribe((data:any) =>{
          this.notify.notify('Unit moved successfully!','success');
          this.moveGisUnit(this.selected_unit_ids, this.selectedFloor);
          if(this.data.move){
            this.data.pObj.getUnitData();
          }
        });
      }
      setTimeout(()=>{
        this.dialogRef.close();
      });
    }
  }
  moveGisUnit(selected_units, selected_floor){
    let temp:any = [], body = {};
    selected_units.forEach(id => {
      let gisObj = this.fnc.getArrayValue('id', id, this.data.pObj.unitData);
      temp.push(gisObj.gid);
    });
    body = {
      "floor_uid": selected_floor.gid,
      "unit_uids": temp,
      "updated_by": this.api.user_id
      }
    this.api.putGesData('units', body).subscribe((data:any)=>{
      // this.notify.notify('GIS Updated!','Dismiss');
    }, err=>{

    })
  }
  pasteHere(){
    this.selected_floor_ids = [];
    this.selected_floor_ids.push(this.share.floor_obj.selected_floor_id);
    this.copyUnit();

  }
}
  /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_patch</li>
 * </ol>
 *
 */

@Component({
  selector: 'confirm-remove',
  templateUrl: './confirm-remove.html',
  styleUrls: ['./unit.component.css']
})
export class ConfirmRemove{
  unit_id:number = 0;
  constructor(public dialogRef: MatDialogRef<ConfirmRemove>, @Inject(MAT_DIALOG_DATA) public data: any) {

   }
  ngOnInit() {
    if(this.data.pObj.selected_unit_id){
      this.unit_id = this.data.pObj.selected_unit_id;
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  remove(): void {
    if(this.unit_id){
      let body= {
        "status": -1,
        "updated_by": this.data.pObj.api.user_id
      };
      let url = "deadmin/"+this.data.pObj.assignment_detail.assignment_name+"/properties/"+this.data.pObj.share.block0_obj.selected_property.id+"/floors/"+this.data.pObj.share.floor_obj.selected_floor_id+"/units/"+this.unit_id;
        this.data.pObj.api.patchEpsData(url,body)
        .subscribe({
          next: (data:any) =>{
          let unitIndex = this.data.pObj.fnc.getArrayindex('id',this.unit_id,this.data.pObj.unitData);
          this.data.pObj.getNoOfUnits();
          if(unitIndex >= 0){
            this.data.pObj.unitData.splice(unitIndex,1);
            this.data.pObj.selected_unit_id = [];
            this.data.pObj.share.dynamic_obj.onOptionsRequested('unit');
            this.data.pObj.info.notify('Unit deleted successfully','success');
          }
        },
          error:() =>{
            this.data.pObj.info.notify('Something went wrong try again','error');
          }
        });
    }
    this.dialogRef.close();
  }

}
